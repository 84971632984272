<template>
  <div id="components-back-top-custom">
    <a-tooltip>
      <template #title>回到顶部</template>
      <a-back-top>
        <a-button type="primary" shape="circle">顶</a-button>
      </a-back-top>
    </a-tooltip>
  </div>

  <keep-alive>
    <a-card>
      <a-form ref="formRef" :model="form" :rules="rules" scrollToFirstError hideRequiredMark>
        <table class="table">
          <thead>
            <tr>
              <th colspan="24">
                <a-typography-title :level="2">订单信息录入</a-typography-title>
              </th>
            </tr>
            <tr>
              <th class="left-td" colspan="12">
                <a-form-item label="承运公司" :wrapperCol="{flex:'auto'}" :name="['order','carrierType','value']">
                  <a-select v-model:value="form.order.carrierType.value" style="width: 300px" placeholder="请选择承运商" :bordered="false">
                    <a-select-option :value="1">华月物流有限公司</a-select-option>
                    <a-select-option :value="2">华月恒宝科技有限公司</a-select-option>
                    <!-- <a-select-option :value="3">深圳华雨物流有限公司</a-select-option> -->
                    <a-select-option :value="4">四川久智车途科技有限公司</a-select-option>
                  </a-select>
                </a-form-item>
              </th>
              <th class="right-td" colspan="12">录单员：{{$store.state.user.name}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <!--  客户 -->
              <td rowspan="3" colspan="2" width="6%">
                <strong>客户</strong>
                <a-button type="link" @click="chooseCustomerVisible = true">选择</a-button>
              </td>
              <td class="label-td" colspan="2" width="5%">姓名</td>
              <td colspan="4" width="22%">
                {{form.orderCustomer.customerName}}
              </td>
              <!-- 对接人 -->
              <td rowspan="3" colspan="2" width="6%">
                <strong>对接人</strong>
              </td>
              <td class="label-td" colspan="2" width="6%">姓名</td>
              <td colspan="4" width="22%">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['order','consignor','name']">
                  <a-input v-model:value="form.order.consignor.name" autocomplete="off" allowClear />
                </a-form-item>
              </td>
              <!-- 取车人 -->
              <td rowspan="3" colspan="2" width="6%">
                <strong>取车人</strong>
                <a-button type="link" @click="copyConsignor">同步</a-button>
              </td>
              <td class="label-td" colspan="2" width="6%">姓名</td>
              <td colspan="4" width="22%">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['order','picker','name']">
                  <a-input v-model:value="form.order.picker.name" autocomplete="off" allowClear />
                </a-form-item>
              </td>
            </tr>
            <tr>
              <!--  客户 -->
              <td class="label-td" colspan="2" width="5%">证件号</td>
              <td colspan="4" width="22%">
                {{form.orderCustomer.certificateNumber}}
              </td>
              <!-- 对接人 -->
              <td class="label-td" colspan="2" width="6%">证件号</td>
              <td colspan="4" width="22%">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['order','consignor','certificateNumber']">
                  <a-input v-model:value="form.order.consignor.certificateNumber" autocomplete="off" allowClear />
                </a-form-item>
              </td>
              <!-- 取车人 -->
              <td class="label-td" colspan="2" width="6%">证件号</td>
              <td colspan="4" width="22%">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['order','picker','certificateNumber']">
                  <a-input v-model:value="form.order.picker.certificateNumber" autocomplete="off" allowClear />
                </a-form-item>
              </td>
            </tr>
            <tr>
              <!--  客户 -->
              <td class="label-td" colspan="2" width="5%">电话</td>
              <td colspan="4" width="22%">{{form.orderCustomer.customerMobile}}</td>
              <!-- 对接人 -->
              <td class="label-td" colspan="2" width="6%">电话</td>
              <td colspan="4" width="22%">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['order','consignor','mobile']">
                  <a-input v-model:value="form.order.consignor.mobile" autocomplete="off" allowClear />
                </a-form-item>
              </td>
              <!-- 取车人 -->
              <td class="label-td" colspan="2" width="6%">电话</td>
              <td colspan="4" width="22%">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['order','picker','mobile']">
                  <a-input v-model:value="form.order.picker.mobile" autocomplete="off" allowClear />
                </a-form-item>
              </td>
            </tr>
            <tr>
              <td class="label-td" colspan="2" width="6%">起运地</td>
              <td colspan="6" width="22%">
                <a-form-item :wrapperCol="{flex:'auto'}" name="startAddress">
                  <a-cascader v-model:value="form.startAddress" :options="$store.state.app.cityDict" :show-search="{ filter }" @change="setStartAddress" placeholder="请选择起运地" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="6%">目的地</td>
              <td colspan="6" width="22%">
                <a-form-item :wrapperCol="{flex:'auto'}" name="endAddress">
                  <a-cascader v-model:value="form.endAddress" :options="$store.state.app.cityDict" :show-search="{ filter }" @change="setEndAddress" placeholder="请选择目的地" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="6%">所属区域</td>
              <td colspan="6" width="22%">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['order','officeOrgId']">
                  <a-select v-model:value="form.order.officeOrgId" allowClear>
                    <a-select-option v-for="item in orgs" :key="item.id">{{item.name}}</a-select-option>
                  </a-select>
                </a-form-item>
              </td>
            </tr>
            <tr>
              <td class="label-td" colspan="2">发票</td>
              <td colspan="2" class="left-td">
                <a-form-item :wrapperCol="{flex:'auto'}">
                  <a-switch v-model:checked="form.order.isInvoice.value" :checkedValue="1" :unCheckedValue="0" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="6%">业务类型</td>
              <td colspan="2">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['order','orderFund','value']">
                  <a-select v-model:value="form.order.orderFund.value" allowClear>
                    <a-select-option :value="1">普通</a-select-option>
                    <a-select-option :value="2">垫资</a-select-option>
                  </a-select>
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="6%">结算方式</td>
              <td colspan="6">
                <a-row :gutter="8">
                  <a-col :span="8">
                    <a-form-item :wrapperCol="{flex:'auto'}" :name="['order','orderSettlement','value']">
                      <a-select v-model:value="form.order.orderSettlement.value" allowClear>
                        <a-select-option :value="1">货到付款</a-select-option>
                        <a-select-option :value="2">起运地付清</a-select-option>
                        <a-select-option :value="3">预付X到付X</a-select-option>
                        <a-select-option :value="5" :disabled="form.orderCustomer.settlementMode.value === 1">记账</a-select-option>
                        <a-select-option :value="4" :disabled="form.orderCustomer.settlementMode.value !== 2 ">月结</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="16" v-if="form.order.orderSettlement.value === 3">
                    <a-form-item label="预付（元）" :name="['order','advanceCharge']">
                      <a-input v-model:value="form.order.advanceCharge" prefix="￥" />
                    </a-form-item>
                  </a-col>
                  <a-col :span="16" v-if="form.order.orderSettlement.value === 5">
                    <a-form-item label="记账期限" :name="['order','deadline']">
                      <a-input-number v-model:value="form.order.deadline" />
                    </a-form-item>
                  </a-col>
                </a-row>
              </td>
              <td class="label-td" colspan="2" width="6%">产品服务</td>
              <td colspan="6">
                <a-row :gutter="8">
                  <a-col :span="8">
                    <a-form-item :wrapperCol="{flex:'auto'}">
                      <a-select v-model:value="form.order.productId">
                        <a-select-option :value="null">无产品</a-select-option>
                        <a-select-option v-for="item in products" :key="item.value">{{item.label}}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="16" v-if="form.order.productId === '1392734185333278722'">
                    <a-form-item label="时效（天）" :name="['order','aging']">
                      <a-input v-model:value="form.order.aging" />
                    </a-form-item>
                  </a-col>
                </a-row>
              </td>
            </tr>
            <tr>
              <td class="label-td" colspan="2">返款</td>
              <td colspan="2" class="left-td">
                <a-form-item :wrapperCol="{flex:'auto'}">
                  <a-switch v-model:checked="form.order.isRefund.value" :checkedValue="1" :unCheckedValue="0" />
                </a-form-item>
              </td>
              <template v-if="form.order.isRefund.value">
                <td class="label-td" colspan="2" width="6%">返款金额</td>
                <td colspan="2">
                  <a-form-item :wrapperCol="{flex:'auto'}" :name="['order','refundFee']">
                    <a-input v-model:value="form.order.refundFee" prefix="￥" />
                  </a-form-item>
                </td>
                <td class="label-td" colspan="2" width="6%">收款人</td>
                <td colspan="6">{{form.order.refunder}}</td>
                <td class="label-td" colspan="2" width="6%">返款电话</td>
                <td colspan="6">{{form.order.refunderMobile}}</td>
              </template>
              <td v-else colspan="20" />
            </tr>
            <tr>
              <td colspan="2" width="6%" class="label-td">备注</td>
              <td colspan="22">
                <a-textarea v-model:value="form.order.remark" :rows="3" :maxlength="100" showCount />
              </td>
            </tr>
          </tbody>
        </table>

        <template v-for="(item,index) in form.vehicles" :key="index">
          <table v-if="item.isAlone" class="table">
            <tr class="vehicle-div">
              <td class="label-td" rowspan="7" width="6%">
                <strong>单台车辆</strong>
                <a-button type="link" @click="copyVehicle(index)">复加</a-button>
                <a-button type="link" v-if="form.vehicles.length > 1" @click="deleteVehicle(index)">删除</a-button>
              </td>
              <td class="label-td" colspan="2" width="8%">车型</td>
              <td colspan="4" width="20%">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['vehicles',index,'brands']" :rules="[{required: true, message: '请选择车型', trigger: 'change',type:'array'}]">
                  <a-cascader v-model:value="item.brands" :options="carBrands" :show-search="{ filter }" @change="(value)=>{[form.vehicles[index].brandId,form.vehicles[index].modelId]=value}" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">车架号</td>
              <td colspan="4" width="14%">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['vehicles',index,'vinNo']" :rules="[{required: true, message: '请填写车架号', trigger: 'blur'},{min:6,max:8,message: '车架号长度为6-8位', trigger: 'blur'},{validator:vinNoRepeat,message: '车架号重复', trigger: 'blur'}]">
                  <a-input v-model:value="item.vinNo" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">车辆状况</td>
              <td colspan="4" class="left-td" width="14%">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['vehicles',index,'status','value']" :rules="[{required: true, message: '请选择', trigger: 'change',type:'number'}]">
                  <a-select v-model:value="item.status.value" allowClear>
                    <a-select-option :value="1">正常</a-select-option>
                    <a-select-option :value="2">开不动</a-select-option>
                    <a-select-option :value="3">事故车</a-select-option>
                  </a-select>
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">运费（元）</td>
              <td colspan="4" width="14%">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['vehicles',index,'freight']" :rules="[{required: true, message: '请填写运费', trigger: 'blur'},{ pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }]">
                  <a-input v-model:value="item.freight" prefix="￥" />
                </a-form-item>
              </td>
            </tr>
            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="6%">保险</td>
              <td colspan="4" width="20%">
                <a-form-item :name="['vehicles',index,'insuranceCompany','value']" :rules="[{required: true, message: '请选择保险购买公司', trigger: 'change',type:'number'}]">
                  <a-radio-group v-model:value="item.insuranceCompany.value">
                    <a-radio :value="1">否</a-radio>
                    <a-radio :value="2">太平洋（江）</a-radio>
                    <a-radio :value="5">太平洋（深）</a-radio>
                  </a-radio-group>
                </a-form-item>
              </td>
              <td class="label-td" colspan="6" width="22%">
                {{item.insuranceCompany?item.insuranceCompany.label:''}}
              </td>
              <td class="label-td" colspan="2" width="8%">保额（万元）</td>
              <td colspan="4" width="14%">
                <a-form-item v-if="item.insuranceCompany.value > 1" :name="['vehicles',index,'insuranceFee']" :rules="[{required: true, message: '请填写额度', trigger: 'blur'},{ pattern: numberValidate, message: '数字格式不正确', trigger: 'blur' }]">
                  <a-input v-model:value="item.insuranceFee" prefix="￥" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">保费（元）</td>
              <td colspan="4" width="14%">
                <a-form-item v-if="item.insuranceCompany.value > 1" :name="['vehicles',index,'insurancePremium']" :rules="[{required: true, message: '请填写额度', trigger: 'blur'},{ pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }]">
                  <a-input v-model:value="item.insurancePremium" prefix="￥" />
                </a-form-item>
              </td>
            </tr>

            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="8%">取车</td>
              <td colspan="2" width="8%">
                <a-form-item :wrapperCol="{flex:'auto'}">
                  <a-switch v-model:checked="item.isPlaceIn.value" :checkedValue="1" :unCheckedValue="0" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="12%">取车费（元）</td>
              <td colspan="6" width="22%">
                <a-form-item v-if="item.isPlaceIn.value" :wrapperCol="{flex:'auto'}" :name="['vehicles',index,'placeInFee']" :rules="[{required: true, message: '请填写取车费', trigger: 'blur'},{ pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }]">
                  <a-input v-model:value="item.placeInFee" prefix="￥" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">取车地址</td>
              <td colspan="8" width="36%">
                <a-form-item v-if="item.isPlaceIn.value" :wrapperCol="{flex:'auto'}">
                  <a-input v-model:value="item.placeInAddress" />
                </a-form-item>
              </td>
            </tr>
            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="8%">送车</td>
              <td colspan="2">
                <a-form-item :wrapperCol="{flex:'auto'}">
                  <a-switch v-model:checked="item.isTakeOut.value" :checkedValue="1" :unCheckedValue="0" />
                </a-form-item>
              </td>

              <td class="label-td" colspan="2" width="12%">送车费（元）</td>
              <td colspan="6" width="22%">
                <a-form-item v-if="item.isTakeOut.value" :wrapperCol="{flex:'auto'}" :name="['vehicles',index,'takeOutFee']" :rules="[{required: true, message: '请填写送车费', trigger: 'blur'},{ pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }]">
                  <a-input v-model:value="item.takeOutFee" prefix="￥" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">送车地址</td>
              <td colspan="8" width="36%">
                <a-form-item v-if="item.isTakeOut.value" :wrapperCol="{flex:'auto'}">
                  <a-input v-model:value="item.takeOutAddress" />
                </a-form-item>
              </td>

            </tr>

            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="8%">备注</td>
              <td colspan="22">
                <a-textarea v-model:value="item.remark" :rows="2" :maxlength="100" showCount />
              </td>
            </tr>
          </table>

          <table v-else class="table">
            <tr class="vehicle-div">
              <td class="label-td" rowspan="7" width="6%">
                <strong>多台车辆</strong>
                <a-button type="link" v-if="form.vehicles.length > 1" @click="deleteVehicle(index)">删除</a-button>
              </td>
              <td class="label-td" colspan="2" width="8%">车型</td>
              <td colspan="4" width="20%">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['vehicles',index,'brands']" :rules="[{required: true, message: '请选择车型', trigger: 'change',type:'array'}]">
                  <a-cascader v-model:value="item.brands" :options="carBrands" :show-search="{ filter }" @change="(value)=>{[form.vehicles[index].brandId,form.vehicles[index].modelId]=value}" />
                </a-form-item>
              </td>

              <td class="label-td" colspan="2" width="8%">车辆状况</td>
              <td colspan="4" class="left-td" width="14%">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['vehicles',index,'status','value']" :rules="[{required: true, message: '请选择', trigger: 'change',type:'number'}]">
                  <a-select v-model:value="item.status.value" allowClear>
                    <a-select-option :value="1">正常</a-select-option>
                    <a-select-option :value="2">开不动</a-select-option>
                    <a-select-option :value="3">事故车</a-select-option>
                  </a-select>
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">运费（元）</td>
              <td colspan="4" width="14%">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['vehicles',index,'freight']" :rules="[{required: true, message: '请填写运费', trigger: 'blur'},{ pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }]">
                  <a-input v-model:value="item.freight" prefix="￥" />
                </a-form-item>
              </td>

              <td colspan="6" rowspan="2" width="22%">
                <a-textarea v-model:value="item.vinNoStr" :rows="7" style="height:100%" placeholder="请从excel表中复制多个车架号粘贴在此" />
                <a-button type="link" class="text-btn" @click="strSplit(index)">确定</a-button>
              </td>
            </tr>

            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="8%" style="height:auto;">车架号</td>
              <td colspan="16" class="left-td" style="height:auto;" width="72%">
                <a-form-item :wrapperCol="{flex:'auto'}" :name="['vehicles',index,'vinNo']" :rules="[{required: true, message: '请添加车架号', trigger: 'change',type:'array'},{validator:vinNosRepeat,trigger: 'change',type:'array'}]" validateFirst>
                  <template v-for="(vinNo, j) in item.vinNo" :key="vinNo">
                    <a-tag @close="delVinNo(index,j)" closable>
                      <template #icon>
                        <span style="color:red"> {{ `${j + 1}`.padStart(3, 0) }}: </span>
                      </template>
                      {{vinNo}}
                    </a-tag>
                  </template>
                </a-form-item>
              </td>
            </tr>

            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="6%">保险</td>
              <td colspan="4" width="20%">
                <a-form-item :name="['vehicles',index,'insuranceCompany','value']" :rules="[{required: true, message: '请选择保险购买公司', trigger: 'change',type:'number'}]">
                  <a-radio-group v-model:value="item.insuranceCompany.value">
                    <a-radio :value="1">否</a-radio>
                    <a-radio :value="2">太平洋（江）</a-radio>
                    <a-radio :value="5">太平洋（深）</a-radio>
                  </a-radio-group>
                </a-form-item>
              </td>
              <td class="label-td" colspan="6" width="22%">
                {{item.insuranceCompany?item.insuranceCompany.label:''}}
              </td>
              <td class="label-td" colspan="2" width="8%">保额（万元）</td>
              <td colspan="4" width="14%">
                <a-form-item v-if="item.insuranceCompany.value > 1" :name="['vehicles',index,'insuranceFee']" :rules="[{required: true, message: '请填写额度', trigger: 'blur'},{ pattern: numberValidate, message: '数字格式不正确', trigger: 'blur' }]">
                  <a-input v-model:value="item.insuranceFee" prefix="￥" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">保费（元）</td>
              <td colspan="4" width="14%">
                <a-form-item v-if="item.insuranceCompany.value > 1" :name="['vehicles',index,'insurancePremium']" :rules="[{required: true, message: '请填写额度', trigger: 'blur'},{ pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }]">
                  <a-input v-model:value="item.insurancePremium" prefix="￥" />
                </a-form-item>
              </td>
            </tr>

            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="8%">取车</td>
              <td colspan="2" width="8%">
                <a-form-item :wrapperCol="{flex:'auto'}">
                  <a-switch v-model:checked="item.isPlaceIn.value" :checkedValue="1" :unCheckedValue="0" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="12%">取车费（元）</td>
              <td colspan="6" width="22%">
                <a-form-item v-if="item.isPlaceIn.value" :wrapperCol="{flex:'auto'}" :name="['vehicles',index,'placeInFee']" :rules="[{required: true, message: '请填写取车费', trigger: 'blur'},{ pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }]">
                  <a-input v-model:value="item.placeInFee" prefix="￥" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">取车地址</td>
              <td colspan="8" width="36%">
                <a-form-item v-if="item.isPlaceIn.value" :wrapperCol="{flex:'auto'}">
                  <a-input v-model:value="item.placeInAddress" />
                </a-form-item>
              </td>
            </tr>
            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="8%">送车</td>
              <td colspan="2">
                <a-form-item :wrapperCol="{flex:'auto'}">
                  <a-switch v-model:checked="item.isTakeOut.value" :checkedValue="1" :unCheckedValue="0" />
                </a-form-item>
              </td>

              <td class="label-td" colspan="2" width="12%">送车费（元）</td>
              <td colspan="6" width="22%">
                <a-form-item v-if="item.isTakeOut.value" :wrapperCol="{flex:'auto'}" :name="['vehicles',index,'takeOutFee']" :rules="[{required: true, message: '请填写送车费', trigger: 'blur'},{ pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }]">
                  <a-input v-model:value="item.takeOutFee" prefix="￥" />
                </a-form-item>
              </td>
              <td class="label-td" colspan="2" width="8%">送车地址</td>
              <td colspan="8" width="36%">
                <a-form-item v-if="item.isTakeOut.value" :wrapperCol="{flex:'auto'}">
                  <a-input v-model:value="item.takeOutAddress" />
                </a-form-item>
              </td>

            </tr>

            <tr class="vehicle-div">
              <td class="label-td" colspan="2" width="8%">备注</td>
              <td colspan="22">
                <a-textarea v-model:value="item.remark" :rows="2" :maxlength="100" showCount />
              </td>
            </tr>
          </table>
        </template>

        <div style="text-align:center">
          <a-button type="link" @click="addVehicle(true)">
            <PlusOutlined /> 单台添加
          </a-button>
          <a-button type="link" @click="addVehicle(false)" danger>
            <PlusOutlined /> 多台添加
          </a-button>
        </div>
        <div class="save-button">
          <a-space>
            <a-button type="primary" :loading="subLoading" @click="save">保存</a-button>
          </a-space>
        </div>
      </a-form>
    </a-card>
  </keep-alive>
  <ChooseCustomer v-if="chooseCustomerVisible" v-model:visible="chooseCustomerVisible" @select="chooseCustomer" />
</template>
<script>
import { list as productList } from '@/api/trans/product'
import { list as orgList, cityOrg } from '@/api/system/organzition'
import { carBrandTree } from '@/api/system/setting'
import { add } from '@/api/trans/order'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, onMounted, reactive, toRefs } from 'vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import { message, Modal } from 'ant-design-vue'
import { cloneDeep } from 'lodash-es'
import ChooseCustomer from '@/views/components/chooseCustomer'
import { mobileValidate,phoneValidate, moneyValidate, idNumberValidate, positiveNumberValidate, numberValidate } from '@/utils/validate'
export default {
  name: 'order-add',
  setup () {
    const store = useStore()
    const router = useRouter()
    const formRef = ref()
    const state = reactive({
      subLoading: false,
      products: [],
      carBrands: [],
      orgs: [],
      form: {
        order: {
          picker: {},
          consignor: {},
          carrierType: {},
          isInvoice: { value: 0, label: '' },
          isRefund: { value: 0, label: '' },
          isPeerCollection: { value: 0, label: '' },
          refundFee: '',
          refunderMobile: '',
          productId: null,
          orderFund: {},
          orderSettlement: {},
          advanceCharge: '',
          deadline: undefined,
          startAddress: {},
          endAddress: {}
        },
        orderCustomer: {
          businessType: {},
          settlementMode: { value: 1 }
        },
        startAddress: [],
        endAddress: [],
        vehicles: []
      },
      rules: {
        order: {
          carrierType: { value: { required: true, message: '请选择承运公司', trigger: 'change', type: 'number' } },
          consignor: {
            name: { required: true, message: '请填写交车人姓名', trigger: 'blur' },
            certificateNumber: { pattern: idNumberValidate, message: '请填写正确的身份证号', trigger: 'blur' },
            mobile: [
              { required: true, message: '请填写交车人联系方式', trigger: 'blur' },
              { pattern: phoneValidate, message: '请填写正确的联系方式', trigger: 'blur' }
            ]
          },
          picker: {
            name: { required: true, message: '请填写取车人姓名', trigger: 'blur' },
            certificateNumber: { pattern: idNumberValidate, message: '请填写正确的身份证号', trigger: 'blur' },
            mobile: [
              { required: true, message: '请填写取车人联系方式', trigger: 'blur' },
              { pattern: phoneValidate, message: '请填写正确的联系方式', trigger: 'blur' }
            ]
          },
          orderSettlement: { value: { required: true, message: '请选择', trigger: 'change', type: 'number' } },
          advanceCharge: [{ required: true, message: '请填写预付款', trigger: 'blur' }, { pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }],
          deadline: [{ required: true, message: '请填写记账期限', trigger: 'blur', type: 'number' }, { min: 1, max: 15, message: '记账期限必须在2-15天之间', trigger: 'blur', type: 'number' }],
          refundFee: [{ required: true, message: '请填写预付款', trigger: 'blur' }, { pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }],
          peerCollectionFee: [{ required: true, message: '请填写代收金额', trigger: 'blur' }, { pattern: moneyValidate, message: '金额格式不正确', trigger: 'blur' }],
          refunder: { required: true, message: '请填写收款人', trigger: 'blur' },
          refunderMobile: [{ required: true, message: '请填写电话号码', trigger: 'blur' }, { pattern: phoneValidate, message: '号码格式不正确', trigger: 'blur' }],
          orderFund: { value: { required: true, message: '请选择', trigger: 'change', type: 'number' } },
          officeOrgId: { required: true, message: '请选择', trigger: 'change' },
          aging: [{ required: true, message: '请填写时效天数', trigger: 'blur' }, { pattern: positiveNumberValidate, message: '请填写大于0的正数', trigger: 'blur' }]
        },
        startAddress: { required: true, message: '请选择', trigger: 'change', type: 'array' },
        endAddress: { required: true, message: '请选择', trigger: 'change', type: 'array' }

      },
      chooseCustomerVisible: false

    })

    const vinNoRepeat = (rule, value) => {
      let num = 0
      state.form.vehicles.forEach(item => {
        if (item.isAlone) {
          if (value === item.vinNo) {
            num++
          }
        } else {
          item.vinNo.includes(value) && num++
        }
      })
      if (num > 1) {
        return Promise.reject(rule.message)
      }
      return Promise.resolve()
    }

    const vinNosRepeat = (rule, value) => {
      const arr = []
      value.forEach(element => {
        let num = 0
        state.form.vehicles.forEach(item => {
          if (item.isAlone) {
            if (element === item.vinNo) {
              num++
            }
          } else {
            item.vinNo.includes(element) && num++
          }
        })
        if (num > 1) {
          arr.push(element)
        }
      })
      console.log(arr)
      if (arr.length) {
        return Promise.reject(new Error(`车架号 ${arr.join('，')} 重复`))
      }
      return Promise.resolve()
    }

    const filter = (inputValue, path) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    }

    onMounted(() => {
      // 删除缓存
      store.commit('CLOSE_CACHE_PAGE', router.currentRoute.value.query.backRoute)

      productList({}).then(res => {
        state.products = res.data
      })
      carBrandTree({}).then(res => {
        state.carBrands = res.data
      })
      orgList({ orgType: 1 }).then(res => {
        state.orgs = res.data
      })
    })

    const chooseCustomer = item => {
      state.form.orderCustomer = {
        customerId: item.id,
        customerName: item.name,
        settlementMode: item.settlementMode,
        certificateNumber: item.certificateNumber,
        customerMobile: item.mobile
      }
      state.form.order.consignor = {
        name: item.receiver,
        certificateNumber: item.receiverCertificateNumber,
        mobile: item.receiverMobile
      }
      state.form.order.refunder = item.receiver
      state.form.order.refunderMobile = item.receiverMobile
    }

    const copyConsignor = () => {
      state.form.order.picker = { ...state.form.order.consignor }
    }

    const setStartAddress = (value, selectedOptions) => {
      state.form.order.startAddress.province = value[0]
      state.form.order.startAddress.city = value[1]
      state.form.order.startAddress.area = value[2]

      switch (value.length) {
        case 0:
          state.form.order.officeOrgId = ''
          break
        case 1:
          cityOrg({ provinceId: value[0], city: 0 }).then(res => {
            if (res.code === 10000) {
              state.form.order.officeOrgId = res.data.orgId
            }
          })
          break
        default:
          cityOrg({ provinceId: value[0], city: value[1] }).then(res => {
            if (res.code === 10000) {
              state.form.order.officeOrgId = res.data.orgId
            }
          })
      }
    }

    const setEndAddress = (value, selectedOptions) => {
      state.form.order.endAddress.province = value[0]
      state.form.order.endAddress.city = value[1]
      state.form.order.endAddress.area = value[2]
    }

    const addVehicle = (isAlone = false) => {
      state.form.vehicles.push({
        isAlone: isAlone,
        brands: [],
        brandName: '',
        vinNo: [],
        vinNoStr: '',
        brandId: '',
        modelId: '',
        insuranceCompany: { value: undefined },
        status: {},
        freight: '',
        insurancePremium: '',
        insuranceFee: '',
        isRefund: { value: 0, label: '' },
        refundFee: '',
        refunderMobile: '',
        isPlaceIn: { value: 0, label: '' },
        isTakeOut: { value: 0, label: '' },
        takeOutAddress: '',
        takeOutFee: 0,
        placeInAddress: '',
        placeInFee: 0
      })
    }

    const copyVehicle = index => {
      state.form.vehicles.splice(index, 0, cloneDeep(state.form.vehicles[index]))
    }

    const strSplit = index => {
      const splitVinNos = state.form.vehicles[index].vinNoStr.trim().split(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={ }|《》？：“”【】、；‘'，。、\s]/im).filter(item => item !== '')
      const vinNos = splitVinNos.concat(state.form.vehicles[index].vinNo)
      const newVinNos = []
      const repeatVin = []
      const map = new Map()
      for (const vinNo of vinNos) {
        if (map.has(vinNo)) {
          repeatVin.push(vinNo)
        } else {
          map.set(vinNo)
          newVinNos.push(vinNo)
        }
      }
      if (repeatVin.length > 0) {
        Modal.warning({
          content: `车架号 ${repeatVin.join(',')} 重复，已自动去重`
        })
      }
      state.form.vehicles[index].vinNo = newVinNos
      state.form.vehicles[index].vinNoStr = ''
    }

    const delVinNo = (vehivleIndex, vinNoIndex) => {
      state.form.vehicles[vehivleIndex].vinNo.splice(vinNoIndex, 1)
    }

    const deleteVehicle = index => {
      state.form.vehicles.splice(index, 1)
    }

    const save = () => {
      formRef.value.validateFields().then(() => {
        state.subLoading = true
        if (!state.form.orderCustomer.customerId) {
          Modal.warning({
            content: '请选择客户'
          })
        } else {
          // 组装表单
          const form = cloneDeep(state.form)
          const vehicles = []
          form.vehicles.forEach(item => {
            if (item.isAlone) {
              vehicles.push(item)
            } else {
              vehicles.push.apply(vehicles, item.vinNo.map(val => {
                const vehicle = { ...item }
                vehicle.vinNo = val
                return vehicle
              }))
            }
          })

          delete form.vehicles

          if (!vehicles.length) {
            Modal.warning({ content: '请添加车辆信息' })
            state.subLoading = false
          } else {
            add({ ...form, orderVehicles: vehicles }).then(res => {
              state.subLoading = false
              if (res.code === 10000) {
                message.success(res.msg)
                const currentRouteName = router.currentRoute.value.name
                router.replace({ name: router.currentRoute.value.query.backRoute }).then(() => {
                  store.commit('CLOSE_TAB', currentRouteName)
                })
              }
            }).catch(err => {
              state.subLoading = false
              console.log(err)
            })
          }
        }
      })
    }

    return {
      formRef,
      ...toRefs(state),
      vinNoRepeat,
      vinNosRepeat,
      filter,
      chooseCustomer,
      addVehicle,
      copyVehicle,
      copyConsignor,
      strSplit,
      delVinNo,
      deleteVehicle,
      save,
      setStartAddress,
      setEndAddress,
      mobileValidate,
      phoneValidate,
      moneyValidate,
      idNumberValidate,
      numberValidate
    }
  },
  components: {
    PlusOutlined,
    ChooseCustomer
  }
}
</script>
<style lang="less" scoped>
#components-back-top-custom .ant-back-top {
  bottom: 160px;
  right: 40px;
}

.table {
  width: 100%;

  font-size: 14px;

  td,
  th {
    text-align: center;
    padding: 5px 10px;
  }
  td {
    position: relative;
    border: 1px solid #000;
    max-width: 300px;
    height: 40px;
  }

  .left-td {
    text-align: left;
  }
  .right-td {
    text-align: right;
  }

  .label-td {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }
  .ant-form-item {
    margin: 0;
  }
  .text-btn {
    position: absolute;
    bottom: 12px;
    right: 12px;
    text-align: right;
  }
}

.save-button {
  text-align: right;
}

.vehicle-div {
  background: #eee;
}
</style>
